<template>
  <div
    class="caption"
    v-bind:style="{
      left: localCaption.start * ratio + 'px',
      width: localCaption.dur * ratio + 'px',
    }"
    v-bind:class="{
      dragging: isDragging,
    }"
    @dragstart="startDrag($event, localCaption)"
    @drag="drag($event, localCaption)"
    @dragend="dragEnd($event, localCaption)"
    :draggable="!isDurationDragging"
    @click="click"
  >
    <a-tooltip placement="top" :title="localCaption.text">
      <div class="text">
        {{ localCaption.text }}
      </div>
    </a-tooltip>
    <div class="start">S:{{ localCaption.start }}</div>
    <div class="duration">D:{{ localCaption.dur }}</div>
    <div
      class="duration-handle"
      @dragstart="startDragDuration($event, localCaption)"
      @drag="dragDuration($event, localCaption)"
      @dragend="dragEndDuration($event, localCaption)"
      draggable
    ></div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CaptionEditor',
  props: {
    caption: Object,
    ratio: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      localCaption: JSON.parse(JSON.stringify(this.caption)),
      isDragging: false,
      isDurationDragging: false,
    }
  },
  methods: {
    click() {
      this.$emit('click', this.localCaption)
    },
    startDrag(event) {
      const draggedItem = document.createElement('img')
      event.dataTransfer.setDragImage(draggedItem, 0, 0)
      this.startX = event.layerX
      this.isDragging = true
    },
    drag: _.throttle(function(event, caption) {
      if (this.isDurationDragging) return
      console.log('dragging')
      if (!event.screenX && !event.screenY) return
      const diff = event.layerX - this.startX
      caption.start = caption.start + diff / this.ratio
      caption.start = Number(caption.start.toFixed(3))
    }, 1000 / 60),
    dragEnd(event, caption) {
      const diff = event.layerX - this.startX
      caption.start = caption.start + diff / this.ratio
      caption.start = Number(caption.start.toFixed(3))
      this.isDragging = false
      this.$emit('change', caption)
    },

    startDragDuration(event) {
      event.stopPropagation()
      const draggedItem = document.createElement('img')
      event.dataTransfer.setDragImage(draggedItem, 0, 0)
      this.startX = event.layerX
      this.isDurationDragging = true
      console.log('start dragging')
    },
    dragDuration: _.throttle(function(event, caption) {
      event.stopPropagation()
      if (!event.screenX && !event.screenY) return
      const diff = event.layerX - this.startX
      caption.dur = caption.dur + diff / this.ratio
      caption.dur = Number(caption.dur.toFixed(3))
    }, 1000 / 60),
    dragEndDuration(event, caption) {
      const diff = event.layerX - this.startX
      caption.dur = caption.dur + diff / this.ratio
      caption.dur = Number(caption.dur.toFixed(3))
      this.isDurationDragging = false
    },
  },
}
</script>

<style scoped>
.caption {
  position: absolute;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  top: 0;
  z-index: 1;
}

.dragging {
  border-color: #1890ff;
}

.start {
  position: absolute;
  font-size: 11px;
  color: #888;
  bottom: -18px;
  left: 0;
}

.dragging .start {
  color: #1890ff;
}

.duration {
  position: absolute;
  font-size: 11px;
  color: #888;
  bottom: -18px;
  right: 0;
}

.duration-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  background-color: #888;
  cursor: ew-resize;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
