<template>
  <div class="timeline">
    <Caption
      v-for="(caption, index) in captions"
      :key="caption._id"
      :caption="caption"
      v-on:click="captionClicked"
      v-on:change="captionChanged($event, index)"
    />
    <div
      class="current"
      v-bind:style="{
        transform: `translateX(${currentTime * 100}px`,
      }"
    ></div>
  </div>
</template>

<script>
import Caption from './Caption'

export default {
  name: 'CaptionEditor',
  props: {
    captions: {
      type: Array,
      default: () => [],
    },
    currentTime: Number,
  },
  methods: {
    captionClicked(caption) {
      this.$emit('caption-clicked', caption)
    },
    captionChanged(caption, index) {
      this.$emit('caption-changed', { caption, index })
    },
  },
  components: {
    Caption,
  },
}
</script>

<style scoped>
.timeline {
  overflow: auto;
  position: relative;
  height: 62px;
}

.current {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  background: red;
  z-index: 1;
}
</style>
