<template>
  <div v-if="localVideo">
    <div>
      <a-row type="flex">
        <h2>Edit captions</h2>
        <div style="margin-left: auto">
          <a-button type="primary" @click="handleSave">
            Save
          </a-button>
        </div>
      </a-row>
    </div>
    <a-row type="flex">
      <youtube
        :video-id="localVideo.videoId"
        player-height="250"
        @playing="playing"
        @paused="paused"
        @ready="ready"
      />
    </a-row>
    <CaptionEditor
      :captions="localVideo.captions"
      :current-time="currentTime"
      v-on:caption-clicked="captionClicked"
      v-on:caption-changed="captionChanged"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CaptionEditor from '@/components/CaptionEditor.vue'

export default {
  computed: mapState({
    video: state => {
      console.log('update')
      return state.video.all[state.route.params.id] || {}
    },
  }),
  data() {
    return {
      currentTime: 0,
      localVideo: {},
    }
  },
  methods: {
    ready(event) {
      this.$player = event.target
    },
    playing(event) {
      this.timerId = setInterval(() => {
        this.currentTime = event.target.getCurrentTime()
      }, 1000 / 100)
    },
    paused() {
      clearInterval(this.timerId)
    },
    captionClicked(caption) {
      this.$player.seekTo(caption.start)
    },
    captionChanged({ caption, index }) {
      this.video.captions.splice(index, 1, caption)
    },
    ...mapActions({
      getVideo: 'video/getVideo',
      updateVideo: 'video/updateVideo',
    }),
    handleSave() {
      this.updateVideo(this.localVideo).then(() => {
        this.$message.success('Video has been saved successfully!')
      })
    },
  },
  created() {
    this.getVideo(this.$route.params.id).then(() => {
      this.localVideo = this.video
    })
  },
  components: {
    CaptionEditor,
  },
}
</script>
